<template>
    <div class="whyus">
        <div class="container-padding">
            <Menu />
        </div>
        <div class="img-left"></div>
        <div class="img-right"></div>
        <div class="main-wr whyus-container">
            <div class="whyus-container-header">
              {{ $t('about.why_us_title') }}
            </div>
            <div class="whyus-tabs">
                <div class="whyus-tab" @click="selectedTab = 1">
                    <div class="whyus-tab-header">
                        <div class="whyus-tab-header-text">
                          {{ $t('about.why_us_item_title_1') }}
                        </div>
                        <div class="whyus-tab-header-status">
                            {{selectedTab === 1 ? '-' : '+'}}
                        </div>
                    </div>
                    <div class="whyus-tab-content t-light" :class="{'d-block': selectedTab === 1}">
                        {{ $t('about.why_us_item_text_1') }}
                    </div>
                </div>
                <div class="whyus-tab" @click="selectedTab = 2">
                    <div class="whyus-tab-header">
                        <div class="whyus-tab-header-text">
                          {{ $t('about.why_us_item_title_2') }}
                        </div>
                        <div class="whyus-tab-header-status">
                            {{selectedTab === 2 ? '-' : '+'}}
                        </div>
                    </div>
                    <div class="whyus-tab-content t-light" :class="{'d-block': selectedTab === 2}">
                        {{ $t('about.why_us_item_text_2') }}
                    </div>
                </div>
                <div class="whyus-tab" @click="selectedTab = 3">
                    <div class="whyus-tab-header">
                        <div class="whyus-tab-header-text">
                          {{ $t('about.why_us_item_title_3') }}
                        </div>
                        <div class="whyus-tab-header-status">
                            {{selectedTab === 3 ? '-' : '+'}}
                        </div>
                    </div>
                    <div class="whyus-tab-content t-light" :class="{'d-block': selectedTab === 3}">
                        {{ $t('about.why_us_item_text_3') }}
                    </div>
                </div>
            </div>
            <a :href="'https://user.ipo-markets.online/signup?lang=' + $i18n.locale" class="whyus-container-btn btn-default">
              {{ $t('start_trading') }}
            </a>
        </div>
    </div>
</template>

<script>
import Menu from '../Menu.vue';
export default {
    name: 'WhyUs',
    data: () => ({
        selectedTab: 1,
    }),
    components: { Menu },
}
</script>

<style lang='scss' scoped>
.d-block {
    display: block;
}
.whyus {
    position: relative;
    .img-left {
        position: absolute;
        background-image: url('@/assets/images/AboutUs/whyus_left.png');
        background-size: contain;
        top: 310px;
        left: 0;
        width: 622px;
        height: 834px;
        z-index: 1;
        @media (max-width: 800px) {
            display: none;
        }
    }
    .img-right {
        position: absolute;
        background-image: url('@/assets/images/AboutUs/whyus_right.png');
        background-size: contain;
        top: 160px;
        right: 0;
        width: 972px;
        height: 986px;
        z-index: 1;
        @media (max-width: 800px) {
            width: 590px;
            height: 600px;
            right: -88px;
        }
    }
    &-container {
        position: relative;
        z-index: 2;
        margin-top: 170px;
        @media (max-width: 480px) {
            margin-top: 80px;
        }
        &-header {
            background: linear-gradient(90.01deg, #58FFF5 20.66%, #FF26C2 90.32%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-size: 50px;
            line-height: 62px;
            letter-spacing: 0.08em;
            font-weight: 500;
            @media (max-width: 1600px) {
                font-size: 40px;
                line-height: 50px;
            }
            @media (max-width: 480px) {
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.08em;
            }
        }
        .whyus-tabs {
            width: 1130px;
            margin: 76px auto 0 auto;
            @media (max-width: 1440px) {
                margin-top: 60px;
                width: 900px;
            }
            @media (max-width: 1200px) {
                width: 100%;
            }
            @media (max-width: 480px) {
                margin-top: 40px;
            }
            .whyus-tab {
                &:hover .whyus-tab-header-status {
                    transform: rotate(180deg);
                }
                cursor: pointer;
                margin-bottom: 50px;
                background-blend-mode: overlay;
                border: 1.5px solid rgba(255, 255, 255, 0.6);
                backdrop-filter: blur(30px);
                border-radius: 50px;
                padding: 34px 53px;
                @media (max-width: 1440px) {
                    padding: 25px 45px;
                    margin-bottom: 30px;
                }
                @media (max-width: 1200px) {
                    padding: 20px 35px;
                }
                @media (max-width: 480px) {
                    padding: 13px 18px;
                    border-radius: 17px;
                    margin-bottom: 15px;
                }
                &-header {
                    display: flex;
                    justify-content: space-between;
                    &-text {
                        font-size: 25px;
                        line-height: 31px;
                        letter-spacing: 0.08em;
                        color: #09ECFB;
                        @media (max-width: 1440px) {
                            font-size: 21px;
                            line-height: normal;
                        }
                        @media (max-width: 480px) {
                            font-size: 18px;
                            line-height: 22px;
                            width: 320px;
                        }
                    }
                    &-status {
                        transition: all 0.2s;
                        font-size: 100px;
                        line-height: 5px;
                        @media (max-width: 1440px) {
                            font-size: 70px;
                            line-height: 10px;
                        }
                        @media (max-width: 480px) {
                            font-size: 30px;
                            line-height: 0;
                        }
                    }
                }
                &-content {
                    margin-top: 36px;
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: 0.08em;
                    margin-right: 110px;
                    display: none;
                    height: 138px;
                    @media (max-width: 1440px) {
                        font-size: 16px;
                        line-height: normal;
                        margin-top: 20px;
                        margin-right: 80px;
                        height: 125px;
                    }
                    @media (max-width: 1200px) {
                        height: 160px;
                    }
                    @media (max-width: 900px) {
                        height: 180px;
                    }
                    @media (max-width: 850px) {
                        height: 200px;
                    }
                    @media (max-width: 710px) {
                        height: 240px;
                    }
                    @media (max-width: 650px) {
                        height: 290px;
                    }
                    @media (max-width: 580px) {
                        height: 370px;
                    }
                    @media (max-width: 520px) {
                        height: 420px;
                    }
                    @media (max-width: 480px) {
                        font-size: 13px;
                        line-height: 16px;
                        margin-top: 10px;
                        height: 200px;
                        margin-right: 0;
                        width: 320px;
                    }
                    @media (max-width: 390px) {
                        width: 270px;
                        height: 230px;
                    }
                    @media (max-width: 350px) {
                        width: auto;
                        margin-right: 10px;
                        height: auto;
                    }
                }
            }
        }
        &-btn {
            margin: 0 auto 0 auto;
            @media (max-width: 480px) {
                margin-top: 30px;
            }
        }
    }
}
</style>
